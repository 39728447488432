import {AUTHENTICATE, ENABLE_2FA, ENABLED_USER, DISABLED_USER, UPDATE_PROFILE} from "../constants/actionTypes";

const initialState = {
    accessToken: "",
    isAuthenticated: false,
    isEnable2FA: false,
    user: {}
};

const authReducer = (state = initialState, action) => {

    switch (action.type) {
        case UPDATE_PROFILE:
            const user = {
                ...state.user,
                email: action.profile.email,
                newEmail: action.profile.newEmail,
                lastName: action.profile.lastName,
                firstName: action.profile.firstName,
                isFillProfile: action.profile.isFillProfile,
                unitSystem: action.profile.unitSystem,
                unitTemperature: action.profile.unitTemperature,
                familyMember: action.profile.familyMember,
                isHasFamily: action.profile.isHasFamily,
                language: action.profile.language,
                phone: action.profile.phone
            };

            localStorage.setItem("user", JSON.stringify(user));
            return {...state, user: user};
        case AUTHENTICATE:
            return {
                ...state,
                accessToken: action.accessToken,
                isPass2FA: false,
                user: action.user,
                isAuthenticated: Object.keys(action.user).length > 0
            };
        case ENABLE_2FA:
            return {...state, isEnable2FA: action.isEnable2FA};
        case ENABLED_USER:
            const userEnable = {...state.user, isEnabled: true};
            localStorage.setItem("user", JSON.stringify(userEnable));
            return {...state, user: userEnable};
        case DISABLED_USER:
            const userDisable = {...state.user, isEnabled: false};
            localStorage.setItem("user", JSON.stringify(userDisable));
            return {...state, user: userDisable};
        default:
            return state;
    }
};

export default authReducer;