import {preloaderPageHideAction, preloaderPageShowAction} from "../../actions/preloaderAction";
import {privateApi} from "../../utils/api";
import {formFieldErrorsAction, formInitAction} from "../../actions/formAction";
import {noticeShowAction} from "../../actions/noticeAction";
import {FormattedMessage} from "react-intl";
import {updateProfileAction} from "../../actions/authAction";
import {formErrors, formIsSend} from "./system/formService";
import React from "react";

export const fetchProfileGeneral = (userId) => {
    return (dispatch, state) => {
        const user = state().auth.user;
        let url = '';
        if (user.role === 'PATIENT') {
           url = "/v1/patients/profile/general";
        } else {
            url = "/v1/patients/" + userId + "/profile/general"
        }
        dispatch(preloaderPageShowAction());
        return privateApi.get(url)
            .then(response => {
                const {data} = response;
                dispatch(formInitAction("profile", data));
            }).catch(error => {
                dispatch(noticeShowAction(<FormattedMessage id="notice.default.title"/>, error.response.data.message));
            }).finally(() => dispatch(preloaderPageHideAction()));
    }
};

export const updateProfileGeneral = (userId, updateProfile) => {
    return (dispatch, state) => {
        const user = state().auth.user;
        let url = '';
        if (user.role === 'PATIENT') {
            url = "/v1/patients/profile/general";
        } else {
            url = "/v1/patients/" + userId + "/profile/general"
        }
        dispatch(preloaderPageShowAction());
        return privateApi.post(url, updateProfile)
            .then(response => {
                if (user.id == userId) {
                    dispatch(updateProfileAction({
                        ...user,
                        isFillProfile : true,
                        lastName: updateProfile.lastName,
                        firstName: updateProfile.firstName,
                        phone: updateProfile.phone,
                    }));
                }
                dispatch(formFieldErrorsAction("profile", []));
                dispatch(fetchProfileGeneral(userId));
                return dispatch(noticeShowAction("", <FormattedMessage id="notice.default.msg.save.success.text"/>));
            }).catch(error => {
                if (error.response.status === 400) {
                    dispatch(formErrors("profile", error.response.data.details));
                } else {
                    dispatch(noticeShowAction(<FormattedMessage id="notice.default.title"/>, error.response.data.message));
                }
                dispatch(formIsSend("profile", false));
            }).finally(() => dispatch(preloaderPageHideAction()));
    }
};